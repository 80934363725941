import React from "react"
import Box from "@mui/material/Box"
import T from "@mui/material/Typography"
import SEO from "../components/SEO"
import { navigate, PageProps } from "gatsby"
import Button from "../components/design-system/Button"
import ButtonWrapper from "../components/ButtonWrapper"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import Link from "../components/Link"
import { email } from "@ap-frontend/questions"

const title = "Accessibility statement"

const AccessibilityStatementPage: React.FC<PageProps> = () => {
  return (
    <Box sx={{ maxWidth: 650 }}>
      <SEO title={title} />
      <T variant="h1" component="h1">
        {title}
      </T>
      <T variant="body2">
        This website is run by the University of Cambridge. We want as many
        people as possible to be able to use this website. For example, that
        means you should be able to:
      </T>
      <List component="ul" className="MuiList-unordered">
        <ListItem>
          <T variant="body2">change colours, contrast levels and fonts</T>
        </ListItem>
        <ListItem>
          <T variant="body2">
            zoom in up to 200% without the text spilling off the screen
          </T>
        </ListItem>
        <ListItem>
          <T variant="body2">
            navigate most of the website using just a keyboard
          </T>
        </ListItem>
        <ListItem>
          <T variant="body2">
            navigate most of the website using speech recognition software
          </T>
        </ListItem>
        <ListItem>
          <T variant="body2">
            listen to most of the website using a screen reader (including the
            most recent versions of JAWS, NVDA and VoiceOver)
          </T>
        </ListItem>
      </List>
      <T variant="body2">
        {
          "We've also made the website text as simple as possible to understand."
        }
      </T>
      <T variant="body2">
        <Link href="https://mcmw.abilitynet.org.uk/">
          Get advice from AbilityNet
        </Link>{" "}
        about making your device easier to use if you have a disability.
      </T>
      <T variant="h2">How accessible this website is</T>
      <T variant="body2">
        We know some parts of this website are not fully accessible. This
        includes:
      </T>
      <List component="ul" className="MuiList-unordered">
        <ListItem>
          <T variant="body2">
            keyboard navigation is not possible on all interactive elements
          </T>
        </ListItem>
        <ListItem>
          <T variant="body2">
            screen reader focus can sometimes become trapped
          </T>
        </ListItem>
        <ListItem>
          <T variant="body2">some icons do not have text alternatives</T>
        </ListItem>
      </List>
      <T variant="h2">
        {"What to do if you can't access parts of this website"}
      </T>
      <T variant="body2">
        If you need information on this website in a different format email{" "}
        <Link href={`mailto:${email}`}>{email}</Link>.
      </T>
      <T variant="body2">
        {"We'll consider your request and get back to you in 5 working days."}
      </T>
      <T variant="h2">Reporting accessibility problems with this website</T>
      <T variant="body2">
        {
          "We're always looking to improve the accessibility of this website. If you find any problems that are not listed on this page or think we're not meeting the accessibility requirements, email "
        }
        <Link href={`mailto:${email}`}>{email}</Link>.
      </T>
      <T variant="h2">Enforcement procedure</T>
      <T variant="body2">
        {
          "The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018 (the 'accessibility regulations'). If you're not happy with how we respond to your complaint, "
        }
        <Link href="https://www.equalityadvisoryservice.com/">
          {"contact the Equality Advisory and Support Service (EASS)"}
        </Link>
      </T>
      <T variant="h2">
        {"Technical information about this website's accessibility"}
      </T>
      <T variant="body2">
        {
          "The Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018 (the 'accessibility regulations') do not apply to the University of Cambridge. However, the University of Cambridge is committed to making its websites and mobile applications accessible and in this regard to complying voluntarily with the accessibility regulations. This accessibility statement is published in that context."
        }
      </T>
      <T variant="body2">
        This website is partially compliant with the{" "}
        <Link href="https://www.w3.org/TR/WCAG21/">
          Web Content Accessibility Guidelines version 2.1
        </Link>{" "}
        AA standard, due to the non-compliances listed below.
      </T>
      <T variant="h2">Non-accessible content</T>
      <T variant="body2">
        The content listed below is non-accessible for the following reasons.
      </T>
      <T variant="body2">
        <b>
          Keyboard navigation is not possible on all interactive elements on
          some browsers and devices
        </b>
        . This means someone using only a keyboard may be unable to control some
        interactive elements. This fails WCAG 2.0 A criterion 2.1.1 (Keyboard).
      </T>
      <T variant="body2">We plan to fix this by September 2024.</T>
      <T variant="h2">Non-compliance with the accessibility regulations</T>
      <T variant="body2">
        We plan to fix the following defects by September 2024.
      </T>
      <T variant="body2">
        <b>
          Status messages for text input fields with search functionality are
          unclear for some users of assistive technology.
        </b>{" "}
        This means that the fields do not provide enough information for some
        applicants, for example screen reader users. This fails WCAG 2.1AA
        criterion 4.1.3 (Status messages).
      </T>
      <T variant="body2">
        <b>Screen reader focus can sometimes become trapped.</b> This means that
        people who use a keyboard may get stuck on part of a page, unable to
        navigate away from content or a component. This fails WCAG 2.0 A
        criterion 2.1.2 (No keyboard trap).
      </T>
      <T variant="body2">
        <b>
          The focus may move to the main area of the screen when uploaded
          content is removed.
        </b>{" "}
        This may particularly disadvantage keyboard users and make the page
        difficult to understand and interact with. This fails WCAG 2.0 A
        criterion 2.4.3 (Focus order).
      </T>
      <T variant="body2">
        <b>
          The focus sometimes navigates to the same component multiple times.
        </b>{" "}
        Keyboard users may think these components are multiple, separate
        controls, rather than single components. This fails WCAG 2.0 A criterion
        2.4.3 (Focus order).
      </T>
      <T variant="body2">
        <b>
          Character count information for some text areas is not clear to all
          types of assistive technology.
        </b>{" "}
        This may make it difficult to understand how many more characters can be
        added. This fails WCAG 2.1 AA criterion 4.1.3 (Status messages) and WCAG
        2.0 A criterion 1.3.1 (Info and relationships).
      </T>
      <T variant="body2">
        <b>Validation or error messages are not always clear</b> to all types of
        assistive technology. This means some users may not understand what has
        caused an error until they return to the question or go to a review
        screen. This fails WCAG 2.0 A criterion 1.3.1 (Info and Relationships).
        It also fails WCAG 2.0 A criterion 3.3.1 (Error identification).
      </T>
      <T variant="body2">
        <b>
          Question guidance is not always associated with the relevant form
          field.
        </b>{" "}
        This means that the purpose of the form fields may be unclear to some
        users. This fails WCAG 2.0 A criterion 1.3.1 (Info and relationships).
      </T>
      <T variant="body2">
        <b>Icons for uploading files have poor colour contrast.</b> This means
        that sight-impaired users may not be able to see some important icons
        and understand their purpose. This fails WCAG 2.1 AA criterion 1.4.11
        (Non-text contrast).
      </T>
      <T variant="body2">
        <b>Some icons do not have text alternatives.</b> This means the
        information conveyed by the icons is not available to some applicants,
        such as screen reader users. This fails WCAG 2.0 A criterion 1.1.1
        (Non-text content).
      </T>
      <T variant="body2">
        <b>
          Tables for uploading files do not have headers in all of the columns.
        </b>{" "}
        This means that users may not understand the purpose of some columns.
        This fails WCAG 2.0 A criterion 1.3.1 (Info and relationships).
      </T>
      <T variant="body2">
        <b>
          Some questions asking applicants to select one option as a radio
          button may not be clear
        </b>{" "}
        to users of assistive technology. This means that the purpose of the
        form fields may be unclear to some users. This fails WCAG 2.0 A
        criterion 1.3.1 (Info and relationships).
      </T>
      <T variant="body2">
        <b>Some headings may not be clear</b> to all types of assistive
        technology. This means that some users may not understand the structure
        of the content. This fails WCAG 2.0 A criterion 1.3.1 (Info and
        relationships).
      </T>
      <T variant="body2">
        <b>Text input fields do not autofill.</b> This means that applicants
        with language and memory problems or disabilities, and those with motor
        impairments, will not benefit from the browser automatically filling
        their information for them. This fails WCAG 2.1 AA criterion 1.3.5
        (Identify input purpose).
      </T>
      <T variant="body2">
        <b>The applicant dropdown menu description may be unclear</b> to all
        types of assistive technology. This means that screen reader users may
        not understand the purpose of every component in the menu. This fails
        WCAG 2.0 A criterion 2.4.3 (Focus Order). It also fails WCAG 2.0 A
        criterion 4.1.2 (Name, role, value).
      </T>
      <T variant="body2">
        <b>
          Keyboard navigation is not possible on all interactive elements on
          some browsers and devices.
        </b>{" "}
        This means someone using only a keyboard may be unable to control some
        interactive elements. This fails WCAG 2.0 A criterion 2.1.1 (Keyboard).
      </T>
      <T variant="h2">Disproportionate burden</T>
      <T variant="body2">
        It is not considered that any aspect of My Cambridge Application will
        fall into this category. Areas of non-compliance with the guidelines are
        detailed in other sections of this statement.
      </T>
      <T variant="h2">
        {"Content that's not within the scope of the accessibility regulations"}
      </T>
      <T variant="body2">Not applicable.</T>
      <T variant="h2">How we tested this website</T>
      <T variant="body2">
        This website has been tested between 1 January and 1 March 2023. Tests
        were carried out by an independent internal audit team using a
        purpose-built test suite. The independent organisation AbilityNet
        carried out technical accessibility tests on this website in May 2023.
      </T>
      <T variant="body2">
        We tested our main website platform, available at{" "}
        <Link to="/">https://apply.undergraduate.study.cam.ac.uk/</Link>.
      </T>
      <T variant="h2">{"What we're doing to improve accessibility"}</T>
      <T variant="body2">
        {
          "If we add or change any page, we'll retest it and update this statement."
        }
      </T>
      <T variant="body2">This statement was prepared on 30 June 2023.</T>
      <ButtonWrapper>
        <Button role="link" color="secondary" onClick={() => navigate(-1)}>
          Back
        </Button>
      </ButtonWrapper>
    </Box>
  )
}

export default AccessibilityStatementPage
